<!-- https://svelte.dev/repl/dd6754a2ad0547c5b1c1ea37c0293fef?version=3.59.2 -->

<script lang="ts">
	import Portal from 'svelte-portal';

	export let title = '';
	export let text = '';
	export let portalDivClass = '';
	export let xOffset = 0;
	export let yOffset = 0;
	let isHovered = false;
	let x: number;
	let y: number;

	function mouseOver(event: any) {
		isHovered = true;
		x = event.pageX + 5;
		y = event.pageY + 5;
	}
	function mouseMove(event: any) {
		x = event.pageX + 5;
		y = event.pageY + 5;
	}
	function mouseLeave() {
		isHovered = false;
	}

	// Handling touch start
	function touchStart(event: TouchEvent) {
		isHovered = true;
		if (event.touches.length > 0) {
			x = event.touches[0].pageX + xOffset;
			y = event.touches[0].pageY + yOffset;
		}
	}

	// Handling touch move
	function touchMove(event: TouchEvent) {
		if (event.touches.length > 0) {
			x = event.touches[0].pageX + xOffset;
			y = event.touches[0].pageY + yOffset;
		}
	}

	// Handling touch end
	function touchEnd() {
		isHovered = false;
	}
</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->
{#if title}
	<!-- svelte-ignore a11y-no-static-element-interactions -->
	<div
		class={$$restProps.class ?? ''}
		on:mouseover={mouseOver}
		on:mouseleave={mouseLeave}
		on:mousemove={mouseMove}
		on:touchstart={touchStart}
		on:touchmove={touchMove}
		on:touchend={touchEnd}
		on:touchcancel={touchEnd}
	>
		{#if text}
			{text}
		{:else}
			<slot />
		{/if}
	</div>
{:else if text}
	{text}
{:else}
	<slot />
{/if}

{#if isHovered}
	<Portal>
		<div
			style="top: {y + yOffset}px; left: {x + xOffset}px;"
			class="tooltip z-[60] px-2 py-1 {portalDivClass}"
		>
			<AtomText type="caption">{title}</AtomText>
		</div>
	</Portal>
{/if}

<style>
	.tooltip {
		border: 1px solid #ddd;
		background: white;
		border-radius: 8px;
		position: absolute;
	}
</style>

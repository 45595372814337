<script lang="ts">
	import ButtonLoader from '$lib/loaders/buttonLoader.svelte';
	import { createEventDispatcher } from 'svelte';
	import type { ButtonVariant } from '../models';
	import AtomTooltip from './AtomTooltip.svelte';

	const dispatch = createEventDispatcher();

	export let variant: ButtonVariant = 'default';
	export let size: 'very_small' | 'small' | 'regular' | 'large' = 'regular';
	export let type: 'reset' | 'button' | 'submit' = 'button';
	export let round = false;
	export let fullWidth: boolean | 'sm' = false;
	export let loading = false;
	export let handleLoadingInternally = false;
	export let text: string | undefined = undefined;
	export let disabled = false;
	export let iconClass: string = '';
	export let focusVisible = true;
	export let iconMaxHeightPx: number | undefined = undefined;
	export let tooltip = '';
	export let tooltipDivClass: string = '';
	export let stopPropagation = false;
	export let id: string | undefined = undefined;
	export let reverse = false;
	export let center = true;
	export let icon: any = undefined; // There ar two ways to handle the icon, one is to pass the icon as a slot, the other is to pass the icon as a prop
	// onClick is used for buttons in a table for example
	export let onClick: ((e: any) => void) | undefined = undefined;

	$: hasAnIcon = $$slots.icon || icon;

	let isIconOnly = !text && hasAnIcon;
	let roundButtonRadius: undefined | number = undefined;
	let _iconSize: undefined | number = 32;
	export let iconSize: undefined | number = undefined; // This is an override from the parent

	$: {
		isIconOnly = !text && hasAnIcon;
		if (size === 'very_small') roundButtonRadius = 28;
		if (size === 'small') roundButtonRadius = 32;
		if (size === 'regular') roundButtonRadius = 36;
		if (size === 'large') roundButtonRadius = 40;

		if (size === 'very_small') _iconSize = 3;
		if (size === 'small') _iconSize = 3;
		if (size === 'regular') _iconSize = 4;
		if (size === 'large') _iconSize = 5;
		// We comment the value for tailwind min-h-[32px] min-h-[36px] min-h-[40px], min-w-[32px] min-w-[36px] min-w-[40px]
	}

	$: finalIconClass = `${variant === 'default' ? 'fill-white' : ''}
		${!disabled && variant === 'secondary' ? 'fill-primary' : ''}
		${disabled && variant === 'secondary' ? 'fill-primary-400' : ''}
		${disabled && variant === 'transparent' ? 'fill-gray-400' : ''}
	 	${iconClass}`;

	// Only used for icon only button, when the button is supposed to be fullwidth, we make it so
	$: iconOnlyWidth = fullWidth ? '100%' : `${roundButtonRadius}px`;
</script>

<AtomTooltip title={tooltip} portalDivClass={tooltipDivClass}>
	<svelte:element
		this="button"
		{type}
		{disabled}
		{id}
		data-testid={$$restProps['data-testid']}
		class:bg-primary={!disabled && variant === 'default'}
		class:bg-primary-400={disabled && variant === 'default'}
		class:text-white={variant === 'default'}
		class:hover:bg-primary-500={!disabled && variant === 'default'}
		class:bg-white={variant === 'secondary'}
		class:text-primary-theme-color={!disabled && variant === 'secondary'}
		class:text-primary-theme-color-400={disabled && variant === 'secondary'}
		class:shadow-2px-inset={variant === 'secondary'}
		class:text-gray-800={!disabled && variant === 'transparent'}
		class:text-gray-500={disabled && variant === 'transparent'}
		class:hover:bg-slate-100={!disabled &&
			(variant === 'transparent' || variant === 'secondary')}
		class:px-2={!isIconOnly && size === 'very_small'}
		class:py-1={!isIconOnly && size === 'very_small'}
		class:px-2.5={!isIconOnly && size === 'small'}
		class:py-1.5={!isIconOnly && size === 'small'}
		class:px-3={!isIconOnly && size === 'regular'}
		class:py-2={!isIconOnly && size === 'regular'}
		class:px-3.5={!isIconOnly && size === 'large'}
		class:py-2.5={!isIconOnly && size === 'large'}
		class:w-full={!isIconOnly && fullWidth}
		class:sm:w-auto={fullWidth === 'sm'}
		class:w-fit={!isIconOnly && fullWidth === false}
		class:rounded-full={round}
		class:rounded-md={!round}
		class:justify-start={!center && !reverse}
		class:justify-center={center}
		class:justify-end={!center && reverse}
		class="{isIconOnly ? `min-w-[${iconOnlyWidth}] min-h-[${roundButtonRadius}px] ` : ''} 
	{variant === 'transparent' ? `dark:hover:bg-slate-600 dark:fill-white ` : ''} 
	 inline-flex {reverse
			? 'flex-row-reverse'
			: ''} h-fit shrink-0 items-center gap-x-3 py-1.5 text-sm font-semibold {$$restProps.class} {focusVisible
			? 'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600'
			: ''}"
		on:click={async (e) => {
			if (stopPropagation) e.stopPropagation();

			dispatch('click', e);

			if (handleLoadingInternally) {
				loading = true;
				disabled = true;
			}
			await onClick?.(e);
			if (handleLoadingInternally) {
				loading = false;
				disabled = false;
			}
		}}
		style={(isIconOnly ? `width: ${iconOnlyWidth}; height: ${roundButtonRadius}px;` : '') +
			` ${$$restProps.style || ''} `}
	>
		{#if text}
			{text}

			{#if !hasAnIcon && loading}
				<AtomIcon size={_iconSize} class={finalIconClass} maxHeightPx={iconMaxHeightPx}>
					<ButtonLoader />
				</AtomIcon>
			{/if}
		{/if}
		{#if hasAnIcon}
			<!-- Don't try to do this "if" inside the AtomIcon, it can cause an issue on Safari when the button has an icon, the loader show and then hides. The icon won't be visible anymore -->
			{#if loading}
				<AtomIcon size={_iconSize} class={finalIconClass} maxHeightPx={iconMaxHeightPx}>
					<ButtonLoader />
				</AtomIcon>
			{:else}
				<AtomIcon
					size={iconSize || _iconSize}
					class={finalIconClass}
					maxHeightPx={iconMaxHeightPx}
					{icon}
				>
					<slot name="icon" />
				</AtomIcon>
			{/if}
		{/if}
	</svelte:element>
</AtomTooltip>
